<template>
  <div>
    <CCard class="mob-pd-0px">
      <CCardHeader class="grey-header d-flex mob-flex-column ai-center">
        <CRow class="flex-flow-column">
          <CCol>
            <strong>List of Notifications</strong>
          </CCol>
          
          <p class="desc" >Lists of diy app post</p>
        </CRow>
        <CButton
          @click="navigateToAddNotification(null)"
          size="sm"
          color="info"
          class="ml-auto mob-w100"
          >Add Notification</CButton
        >
      </CCardHeader>

      <CCardBody class="user-manage-table pt-0px">
        <CRow>
          <CAlert v-if="alertValue" show color="danger">
            <div v-if="alertValue && alertValue.hasOwnProperty('valueList')">
              <p v-for="(value, index) in alertValue.valueList" :key="index">
                {{ value }}
              </p>
            </div>
            <div v-if="alertValue && alertValue.hasOwnProperty('codeList')">
              <span>{{ $t("cAlert.errorCode") + " : " }}</span
              ><span v-for="(code, index) in alertValue.codeList" :key="index">
                {{ code + " " }}
              </span>
            </div>
            <div v-if="alertValue && alertValue.constructor == String">
              <p>
                {{ alertValue }}
              </p>
            </div>
          </CAlert>
        </CRow>
        <CRow
          class="fullscreen-ex-row"
          :class="{
            table_count_visible: 
              (userCount && userCount.total) ||
              (userCount && userCount.active) ||
              (userCount && (userCount.inActive || userCount.inActive == 0)),
          }"
        >
          <CCol
            id="user-manage-table"
            class="usersTable border-grey manage-user-table"
          >
            <cTable
              :fields="lsUserTableFields"
              :items="lsUserTableItems != null ? lsUserTableItems : []"
              caption="List Of Post"
              column-filter
              items-per-page-select
              @row-clicked="navigateToUpdateNotification"
              :items-per-page="manageUsersTableWatchList.itemsPerPage"
              hover
              :sorter="{ resetable: true }"
              :pagination="paginationSettings"
              @update:column-filter-value="saveFilterValue"
              :loading="isLoading"
              class="w-100 d-flex flex-flow-wrap"
              :sorterValue="{ column: 'name', asc: 'true' }"
              @filtered-items-change="getFilteredAgentCount"
              @page-change="onPageChange"
              @pagination-change="onPaginationChange"
              :tableWatchList="manageUsersTableWatchList"
              @getFields="onSaveColumns"
              :manageColumn="true"
              :paginationLenOption="true"
            >
              <template #imageUrl="{ item }">
                <td>
                  <div class="px-2">
                    <cDiyThumbnail :imgURL="item.imageUrl" />
                  </div>
                </td>
              </template>
              <template #sub-over-table>
                <div class="table-count-wrap mt-20px-imp min-w-100per">
                  <div class="d-inline-flex">
                    <div class="single-tab" v-if="userCount && userCount.total">
                      <div class="tc-total-wrap">
                        <span>{{ $t("notification.totalUsers") }}</span>
                        <span>{{ userCount.total }}</span>
                      </div>
                    </div>
                    <div
                      class="single-tab"
                      v-if="userCount && userCount.active"
                    >
                      <div class="tc-active-wrap">
                        <span>{{ $t("notification.active") }} </span>
                        <span>{{ userCount.active }}</span>
                      </div>
                    </div>
                    <div
                      class="single-tab"
                      v-if="
                        userCount &&
                          (userCount.inActive || userCount.inActive == 0)
                      "
                    >
                      <div class="tc-inactive-wrap">
                        <span>{{ $t("notification.inactive") }}</span>
                        <span>{{ userCount.inActive }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <!-- <template #status-filter>
                <div>
                  <cDropDown
                    :key="'status'"
                    v-model="status"
                    url="notification-status"
                    :isAllowEmpty="false"
                    :ctrlCode="'value'"
                    :ctrlName="'name'"
                    :isShowCode="false"
                    :isMultiple="false"
                    :isSearchable="true"
                    :selValue="status"
                    v-on:input="onStatusChange"
                    class="hide-invalid-feedback"
                  />
                </div>
              </template> -->

              <template #status="{ item }">
                <td>
                  <!-- <CBadge
                    :color="item.status == 'Active' ? 'success' : 'danger'"
                  > -->
                    {{ item.status }}
                  <!-- </CBadge> -->
                </td>
              </template>

              <template #no-items-view>
                <div class="text-center my-5">
                  <h2>{{ isLoading ? "" : $t("notification.listEmpty") }}</h2>
                </div>
              </template>

              <!-- <template #status-filter>
                <td style="border-top: 0px; height: 100%">
                  <div @click="clearAllFilters()" class="clear-filter-btn">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      version="1.1"
                      id="Capa_1"
                      x="0px"
                      y="0px"
                      viewBox="0 0 373.6 373.6"
                      style="
                        enable-background: new 0 0 373.6 373.6;
                        fill: #1e90ff;
                      "
                      xml:space="preserve"
                    >
                      <g>
                        <g>
                          <path
                            d="M343.7,0H29.9c-5.5,0-10.1,4.9-10.1,10.5v64.2c0,0.3,0,0.6,0.1,1c0,0.1,0.1,0.3,0.1,0.4c0,0.2,0.1,0.3,0.1,0.5    c0,0.2,0.1,0.3,0.1,0.5c0,0.1,0.1,0.3,0.1,0.4c0.1,0.2,0.1,0.4,0.2,0.5c0,0.1,0.1,0.2,0.1,0.4c0.1,0.2,0.1,0.3,0.2,0.5    c0.1,0.1,0.1,0.2,0.2,0.4c0.1,0.2,0.2,0.3,0.2,0.4c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.2,0.3,0.3,0.4c0.1,0.1,0.2,0.3,0.3,0.4    c0.1,0.1,0.2,0.2,0.3,0.4c0.1,0.1,0.2,0.1,0.2,0.2l114.3,124.6v157.5c0,3.9,2.2,7.4,5.7,9.1c1.3,0.6,2.7,0.9,4.2,0.9    c2.4,0,4.7-0.8,6.5-2.4l80.2-68.3c2.2-1.9,3.5-4.7,3.4-7.6v-89.2L351,81.4c0.1-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.2-0.2,0.3-0.4    c0.1-0.1,0.2-0.2,0.3-0.4c0.1-0.2,0.2-0.3,0.3-0.4s0.2-0.3,0.2-0.4c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.1,0.1-0.2,0.2-0.4    c0.1-0.2,0.2-0.3,0.2-0.5c0-0.1,0.1-0.2,0.1-0.4c0.1-0.2,0.1-0.3,0.2-0.5c0-0.1,0.1-0.3,0.1-0.4c0-0.2,0.1-0.3,0.1-0.5    s0.1-0.3,0.1-0.5c0-0.1,0.1-0.3,0.1-0.4c0-0.3,0.1-0.6,0.1-1V10.4C353.8,4.9,349.3,0,343.7,0z M219.5,195.6    c-1.7,1.8-2.7,4.1-2.7,6.6v88.4l-60,51.3V202.2c0-2.5-1-4.8-2.7-6.6L52.6,85H321L219.5,195.6z M333.8,65h-294V20h294V65z"
                          />
                        </g>
                      </g>
                    </svg>
                    Clear All Filter
                  </div>
                </td>
              </template> -->

              <template #actions="{ item }">
                <td class="py-2 d-flex ai-center">
                  <div class="row">
                    <CCol>
                      <div
                        @click.stop="navigateToInfo(item)"
                        class="px-2 edit-icon icon-25px c-pointer"
                        color="info removewrap"
                        v-c-tooltip.hover="{
                          content: $t('allHotels.edit'),
                          active: false,
                        }"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="512pt"
                          viewBox="0 0 512 512"
                          width="512pt"
                          style="fill: #3c4b64"
                        >
                          <path
                            d="m368 511.957031h-309.332031c-32.363281 0-58.667969-26.304687-58.667969-58.667969v-309.332031c0-32.363281 26.304688-58.667969 58.667969-58.667969h181.332031c8.832031 0 16 7.167969 16 16 0 8.832032-7.167969 16-16 16h-181.332031c-14.699219 0-26.667969 11.96875-26.667969 26.667969v309.332031c0 14.699219 11.96875 26.667969 26.667969 26.667969h309.332031c14.699219 0 26.667969-11.96875 26.667969-26.667969v-181.332031c0-8.832031 7.167969-16 16-16s16 7.148438 16 16v181.332031c0 32.363282-26.304688 58.667969-58.667969 58.667969zm0 0"
                          />
                          <path
                            d="m187.136719 340.820312c-4.203125 0-8.300781-1.664062-11.308594-4.691406-3.796875-3.777344-5.417969-9.21875-4.371094-14.445312l15.082031-75.433594c.617188-3.113281 2.152344-5.953125 4.371094-8.171875l220.953125-220.925781c22.867188-22.871094 60.074219-22.871094 82.964844 0 11.070313 11.070312 17.171875 25.792968 17.171875 41.472656s-6.101562 30.398438-17.195312 41.472656l-220.925782 220.949219c-2.21875 2.238281-5.078125 3.753906-8.171875 4.371094l-75.414062 15.082031c-1.046875.214844-2.113281.320312-3.15625.320312zm75.433593-31.082031h.214844zm-45.609374-52.457031-9.410157 47.144531 47.125-9.429687 217.515625-217.511719c5.035156-5.058594 7.808594-11.734375 7.808594-18.859375s-2.773438-13.804688-7.808594-18.859375c-10.367187-10.390625-27.285156-10.390625-37.714844 0zm0 0"
                          />
                          <path
                            d="m453.332031 134.976562c-4.09375 0-8.191406-1.558593-11.304687-4.695312l-60.332032-60.351562c-6.25-6.25-6.25-16.382813 0-22.632813s16.382813-6.25 22.636719 0l60.328125 60.351563c6.25 6.25 6.25 16.382812 0 22.632812-3.136718 3.117188-7.230468 4.695312-11.328125 4.695312zm0 0"
                          />
                        </svg>
                      </div>
                    </CCol>

                    <CCol>
                      <div
                        @click.stop="onConfirmDeleteHotel(item)"
                        color=" danger"
                        v-c-tooltip.hover="{
                          content: $t('allHotels.delete'),
                          active: false,
                        }"
                        class="icon-25px c-pointer"
                      >
                        <CIcon
                          style="color: #cd0606; font-size: 22px"
                          name="cil-trash"
                          size="lg"
                        ></CIcon>
                      </div>
                    </CCol>
                  </div>
                </td>
              </template>
            </cTable>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import cDropDown from "./../../components/form/cDropDown";
import { navigate } from "../../router/index";

import { mapGetters, mapActions } from "vuex";
import {
  PATHNAME,
  COMPONENT_NAME,
  DEFAULT_DROPDOWN_STATUS,
  ORDER,
} from "../../util/constants";
import apiGetAllNotification from "../../services/notificaion/apiGetAllNotifications";
import Logger from "../../services/logger";
import SERVICE_URL, { FILE_NAME, LOG_TYPE } from "../../config/API_DATA";

import {
  getErrorMessage,
  VALIDATE_GL_SUCCESS_RES,
  VALIDATE_SUCCESS_RES
} from "../../util/apiValidate";
import {
  handleJWT,
  getErrorAlertValue,
  addClassToBody,
  removeClassBody,
  OrderByList,
} from "../../util/util";
import BaseConfiguration from "../../config/base.js";
import cTable from "./../../components/dataTable/cTable";
import cDiyThumbnail from "./../../components/diy/cDiyThumbnail";

export default {
  name: COMPONENT_NAME.NOTIFICATION,
  components: {
    cDropDown,
    cTable,
    cDiyThumbnail,
  },

  data: () => {
    return {
      userCount: null,
      manageUsersTableWatchList: {
        filteredlistCount: null,
        itemsPerPage: 25,
        currentPageNum: 1,
      },
      rolesServiceURL: SERVICE_URL.GET_USER_ROLES,
      showAlertModal: false,

      user: {
        emailid: "",
        name: "",
      },
      status: {
        name: DEFAULT_DROPDOWN_STATUS.ALL,
        value: null,
      },

      role: {
        roleName: null,
      },
      paginationSettings: {
        align: "end",
      },
      alertValue: null,

      lsUsers: [],
      lsUserTableItems: [],
      lsUserTableFields: [],
      isLoading: true,
      //fullscreen: false
    };
  },

  methods: {
    ...mapActions("sAddUser", ["SET_SELECTED_USER"]),
    ...mapActions("sManageUsers", {
      SET_USER_COUNT: "SET_USER_COUNT",
      CLEAR_MANAGE_USERS_STORE: "CLEAR_MANAGE_USERS_STORE",
    }),
    ...mapActions("sManageDiyPost", {
      SET_ALL_POST_RESPONSE: "SET_ALL_POST_RESPONSE",
      CLEAR_MANAGE_USERS_STORE: "CLEAR_MANAGE_USERS_STORE",
    }),
    ...mapActions("sAddDiyPost", {
      SET_SELECTED_POST: "SET_SELECTED_POST",
    }),

    ...mapActions("sDiyNotification", {
      SET_ALL_DIY_NOTIFICATION: "SET_ALL_DIY_NOTIFICATION",
      SET_SELECTED_NOTIFICATION: "SET_SELECTED_NOTIFICATION"
     
    }),

    ...mapActions("sMain", {
      ON_PROGRESS_START: "ON_PROGRESS_START",
      ON_PROGRESS_FINISH: "ON_PROGRESS_FINISH",
      ON_PROGRESS_FAIL: "ON_PROGRESS_FAIL",
      ON_PROGRESS_SET: "ON_PROGRESS_SET",
    }),
    onSaveColumns(pFields) {
      try {
        if (BaseConfiguration.isDebug) console.log("onSaveColumns");
        this.lsUserTableFields = pFields;
      } catch (err) {
        let body = Logger.buildRequest(
          COMPONENT_NAME.MANAGE_USERS,
          "error in onSaveColumns",
          err.toString(),
          "onSaveColumns",
          LOG_TYPE.ERROR,
        );
        Logger.getResponse(body);
      }
    },
    onPaginationChange(pItemsPerPage) {
      try {
        if (BaseConfiguration.isDebug) console.log("onPaginationChange");
        this.manageUsersTableWatchList.itemsPerPage = pItemsPerPage;
      } catch (err) {
        let body = Logger.buildRequest(
          COMPONENT_NAME.MANAGE_USERS,
          "error in onPaginationChange",
          err.toString(),
          "onPaginationChange",
          LOG_TYPE.ERROR,
        );
        Logger.getResponse(body);
      }
    },

    onPageChange(pPageNum) {
      try {
        if (BaseConfiguration.isDebug) console.log("onPageChange");
        this.manageUsersTableWatchList.currentPageNum = pPageNum;
      } catch (err) {
        let body = Logger.buildRequest(
          COMPONENT_NAME.MANAGE_USERS,
          "error in onPageChange",
          err.toString(),
          "onPageChange",
          LOG_TYPE.ERROR,
        );
        Logger.getResponse(body);
      }
    },

    getFilteredAgentCount(pFilteredItems) {
      try {
        if (BaseConfiguration.isDebug) console.log("getFilteredAgentCount");
        this.manageUsersTableWatchList.filteredlistCount =
          pFilteredItems.length;
      } catch (err) {
        let body = Logger.buildRequest(
          COMPONENT_NAME.MANAGE_USERS,
          "error in getFilteredAgentCount",
          err.toString(),
          "getFilteredAgentCount",
          LOG_TYPE.ERROR,
        );
        Logger.getResponse(body);
      }
    },
    clearAllFilters() {
      try {
        if (BaseConfiguration.isDebug) console.log("clearAllFilters");

        this.role = { roleName: DEFAULT_DROPDOWN_STATUS.ALL };
        this.status = {
          name: DEFAULT_DROPDOWN_STATUS.ALL,
          value: null,
        };

        if (this.user) {
          Object.keys(this.user).forEach((key) => {
            this.user[key] = "";
          });
        }
        this.lsUserTableItems = JSON.parse(JSON.stringify(this.lsUsers));
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.MANAGE_USERS,
          "error in clearAllFilters",
          err.toString(),
          "clearAllFilters",
          LOG_TYPE.ERROR,
        );
        Logger.getResponse(body);
      }
    },

    saveFilterValue(pUserObj) {
      try {
        if (BaseConfiguration.isDebug) console.log("saveFilterValue");
        if (pUserObj) {
          this.user = pUserObj;
        }
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.MANAGE_USERS,
          "error in saveFilterValue",
          err.toString(),
          "saveFilterValue",
          LOG_TYPE.ERROR,
        );
        Logger.getResponse(body);
      }
    },
    getUserFields() {
      let returnValue = [];
      try {
        if (BaseConfiguration.isDebug) console.log("getUserFields");

        let lsFields = [
          {
            key: "imageUrl",
            // _style: "width:30%",
            label: 'Image',
            isLocked: true,
            sorter: false,
            filter: false,
          },
          {
            key: "title",
            // _style: "width:30%",

            label: 'Title',
            isLocked: true,
            filter: false,
          },
          {
            key: "schedule",
           
            label: 'Schedule Date & Time',
            isLocked: true,
            filter: false,
          },
          // {
          //   key: "tag",
         
          //   label: this.$t("notification.tag"),
          //   isLocked: false,
          // },
          {
            key: "days",
            // _style: "width:30px;",
            label: 'Author',
            isLocked: false,
            filter: false,
          },

          // {
          //   key: "publishDate",
          //   label: this.$t("notification.publishDate"),
          //   // _style: "width:30px",
          //   sorter: false,
          //   isLocked: true,
          // },

          {
            key: "status",
            label: 'Status',
            // _style: "width:20px",
            filter: false,
            isLocked: true,
          },

          // {
          //   key: "actions",
          //   label: this.$t("notification.actions"),
          //   // _style: "width:20px",

          //   sorter: false,
          //   filter: false,
          //   isLocked: true,
          // },
        ];
        returnValue = lsFields;
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.MANAGE_USERS,
          "error in getUserFields",
          err.toString(),
          "getUserFields",
          LOG_TYPE.ERROR,
        );
        Logger.getResponse(body);
      }
      return returnValue;
    },

    showAlert(value) {
      this.alertValue = value;
    },
    onStatusChange(pStatus) {
      try {
        if (BaseConfiguration.isDebug) console.log("onStatusChange");

        this.lsUserTableItems = JSON.parse(JSON.stringify(this.lsUsers));
        if (
          this.lsUserTableItems != null &&
          this.lsUserTableItems.length > 0 &&
          pStatus
        ) {
          let role = this.role;
          this.lsUserTableItems = this.lsUserTableItems.filter(function(user) {
            if (
              user.status == pStatus.name &&
              (user.roles == role.roleName ||
                role.roleName == DEFAULT_DROPDOWN_STATUS.ALL)
            ) {
              return true;
            } else if (
              pStatus.name == DEFAULT_DROPDOWN_STATUS.ALL &&
              user.roles == role.roleName
            ) {
              return true;
            } else if (
              pStatus.name == DEFAULT_DROPDOWN_STATUS.ALL &&
              role.roleName == DEFAULT_DROPDOWN_STATUS.ALL
            ) {
              return true;
            } else {
              return false;
            }
          });
        }

        // this.resetUserRole();
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.MANAGE_USERS,
          "error in on Status Change",
          err.toString(),
          "onStatusChange",
          LOG_TYPE.ERROR,
        );
        Logger.getResponse(body);
      }
    },
    setSelectedNotification(pSelectedUserObj) {
      try {
        if (BaseConfiguration.isDebug) console.log("setSelectedNotification");
     
        if (
          pSelectedUserObj != null &&
          this.GET_ALL_DIY_NOTIFICATION &&
          this.GET_ALL_DIY_NOTIFICATION.length
        ) {
          pSelectedUserObj = this.GET_ALL_DIY_NOTIFICATION.find(function(user) {
            return user.id == pSelectedUserObj.id;
          });
          pSelectedUserObj = JSON.parse(JSON.stringify(pSelectedUserObj));
          this.SET_SELECTED_NOTIFICATION(pSelectedUserObj);
        } else {
          this.SET_SELECTED_NOTIFICATION(null);
        }
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.MANAGE_USERS,
          "error in selecting User obj",
          err.toString(),
          "setSelectedUserObj",
          LOG_TYPE.ERROR,
        );
        Logger.getResponse(body);
      }
    },

    navigateToAddNotification(pSelectedUserObj) {
      try {
        if (BaseConfiguration.isDebug) console.log("navigateToAddNotification");

        this.setSelectedNotification(pSelectedUserObj);
        navigate(PATHNAME.NOTIFICATION_NEW);
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.NOTIFICATION,
          "error in navigate To User",
          err.toString(),
          "navigateToUser",
          LOG_TYPE.ERROR,
        );
        Logger.getResponse(body);
      }
    },
    navigateToUpdateNotification(pSelectedUserObj) {
      try {
        if (BaseConfiguration.isDebug) console.log("navigateToUpdateNotification");

        this.setSelectedNotification(pSelectedUserObj);

        navigate(PATHNAME.NOTIFICATION_NEW);
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.MANAGE_USERS,
          "error in navigate To User Status",
          err.toString(),
          "navigateToUpdateNotification",
          LOG_TYPE.ERROR,
        );
        Logger.getResponse(body);
      }
    },
    async getAllnotification() {
      let returnValue;
      try {
        if (BaseConfiguration.isDebug) console.log("getAllnotification");

        let getAllNotificationRequest = apiGetAllNotification.buildRequest();

        if (getAllNotificationRequest != null) {
          let allNotResponse = await apiGetAllNotification.getResponse(
            getAllNotificationRequest,
          );
          if (VALIDATE_SUCCESS_RES(allNotResponse)) {
            returnValue = allNotResponse.data.data.List;
            // if (allNotResponse.data.List) {
            //   this.userCount = allNotResponse.data.diy_project_project.count;
            //   this.SET_USER_COUNT(this.userCount);
            // }
            this.showAlert(null);
            this.SET_ALL_DIY_NOTIFICATION(returnValue);
          } else {
            let error = getErrorMessage(allNotResponse);

            let isJwt = handleJWT(error);
            if (!isJwt) {
              this.showAlert(getErrorAlertValue(error));
            }
          }
        } else {
          throw "invalid request";
        }
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ALL_POST,
          "error in  get All Users",
          err.toString(),
          "getAllnotification",
          LOG_TYPE.ERROR,
        );
        Logger.getResponse(body);
      }
      return returnValue;
    },

    getUsersTableItems(pAllUserResponse) {
      let retValue;
      try {
        if (BaseConfiguration.isDebug) console.log("getUsersTableItems");

        if (pAllUserResponse && pAllUserResponse.length > 0) {
          pAllUserResponse.forEach((value) => {
            this.lsUsers.push({
              imageUrl: value.imageUrl,
              title: value.title,
              schedule: value.schedule,
              days: value.days,
              status: value.status,
              id: value.id
            });
          });
        } else {
          this.lsUsers = [];
        }
        retValue = JSON.parse(JSON.stringify(this.lsUsers));
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.MANAGE_USERS,
          "error in  getUsersTableItems",
          err.toString(),
          "getUsersTableItems",
          LOG_TYPE.ERROR,
        );
        Logger.getResponse(body);
      }

      return retValue;
    },
    getPropToSortUsers(pUser) {
      try {
        if (BaseConfiguration.isDebug) console.log("getPropToSortUsers");
        if (pUser.firstname) {
          return pUser.firstname.toLowerCase();
        }
      } catch (err) {
        let body = Logger.buildRequest(
          COMPONENT_NAME.MANAGE_USERS,
          "error in getPropToSortUsers",
          err.toString(),
          "getPropToSortUsers",
          LOG_TYPE.ERROR,
        );
        Logger.getResponse(body);
      }
    },
  },

  async created() {
    try {
      if (BaseConfiguration.isDebug) console.log("created");

      let listUsers = [];
      // if (this.GET_ALL_DIY_NOTIFICATION != null && this.GET_ALL_DIY_NOTIFICATION.length > 0) {
      //   listUsers = this.GET_ALL_DIY_NOTIFICATION;
      // } else {
      listUsers = await this.getAllnotification();
      // }
      listUsers = OrderByList(listUsers, this.getPropToSortUsers, ORDER.ASC);

      if (listUsers)
        this.lsUserTableItems = this.getUsersTableItems(
          JSON.parse(JSON.stringify(listUsers)),
        );

      this.lsUserTableFields = this.getUserFields();
      // if (this.GET_POST_COUNT) {
      //   this.userCount = this.GET_POST_COUNT;
      // }
      this.isLoading = false;
    } catch (err) {
      let body = Logger.buildRequest(
        FILE_NAME.MANAGE_USERS,
        "error in  Created",
        err.toString(),
        "created",
        LOG_TYPE.ERROR,
      );
      Logger.getResponse(body);
    }
  },
  computed: {
    ...mapGetters("sManageDiyPost", {
      allPostResponse: "GET_ALL_POST_RESPONSE",
      GET_POST_COUNT: "GET_POST_COUNT",
    }),
      ...mapGetters("sDiyNotification", {
      GET_ALL_DIY_NOTIFICATION: "GET_ALL_DIY_NOTIFICATION",
     
    }),
    ...mapGetters("sSysLogin", {
      GET_ADMIN_SIGNIN_REQ: "GET_ADMIN_SIGNIN_REQ",
      GET_ADMIN_SIGNIN_RES: "GET_ADMIN_SIGNIN_RES",
    }),
  },
  beforeCreate() {
    addClassToBody(COMPONENT_NAME.MANAGE_USERS);
  },

  beforeDestroy() {
    removeClassBody();
  },
};
</script>
